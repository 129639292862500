( function(){
	// ############## 
	//	Directives // These can be removed + reused
	// ##############
	var directives = angular.module('mx.directives');
	directives.requires.push( 'ngFileUpload' , 'vcRecaptcha');

	// -------------------------------------------------------
	//  Submits to the abstract contact form class 
	// 	This is a new version ( mxForm ) is going to be deprecated
	//  This isolates the scope
	// -------------------------------------------------------
	directives.directive('mxFormComponent', ['$parse', '$http', 'Upload', 'mxOptinService',  '$injector' , function($parse, $http, Upload, Optin , $injector){
		return {
			scope : { },
			link : function( $scope, $element, $attrs , ngModel ){ }, // link
			template : function ( scope, element ){ }, // template 
			controller : function($scope, $http, $timeout, $attrs ){
				var $ctrl = $scope.$ctrl = this;
				$ctrl.jsfilled = 'filled';
				$ctrl.mxFormOptions = false;
				$ctrl.data = { contact : false };
				$ctrl.options = {
					action : '/?submit_contact_form_abstract',
					uploading : false
				}
				
								
				// any options that the form should know about ? 
				if( $attrs.mxFormOptions && $attrs.mxFormOptions !== '' ){
					try {
						$ctrl.mxFormOptions = JSON.parse( $attrs.mxFormOptions);
					}catch( $e ){ console.log( $e ); }
				}
				// are we overriding action / uploading / or the default email address		
				if( $attrs.mxFormUploading ) $ctrl.options.uploading = true;
				if( $attrs.mxFormAction ) $ctrl.options.action = $attrs.mxFormAction;
				if( $attrs.mxFormContactEmail ) $ctrl.data.contact = $attrs.mxFormContactEmail;
				// is using recaptcha ? 
				$ctrl.recap  = ( typeof( $attrs.mxFormUseRecaptcha ) !== 'undefined' && $attrs.mxFormUseRecaptcha  ) ? $injector.get( 'vcRecaptchaService') : false;
				$ctrl.recapValidated = false;
				$ctrl.recapFailed = false;
				
				

				if( $ctrl.mxFormOptions  ){
					$ctrl.data.accepted='not';
					$ctrl.data.initials=''
				}
				
				$ctrl.recapValidate = function(){
					return $ctrl.recap.getResponse() !== "";
				}

				/**
				 * Submit to our abstract function to handle all form submissions
				 * 
				 * @var mixed
				 * @access public
				 */
				$ctrl.submit_contact_form_abstract = function(){
					$ctrl.recapFailed = false;
					$ctrl.recapValidated = false;
					if( $ctrl.recap ){
						$ctrl.data.recap = $ctrl.recap.getResponse()
						if( $ctrl.recap.getResponse() === ''){
							$('#failed-recap').modal( 'show');
							$ctrl.recapValidated = false;
							$ctrl.recapFailed = true ;
						}
					}
					if( $ctrl.recapFailed ) return false;
					$ctrl.contact_form_submitting = true;
					$ctrl.data.nofill = typeof($ctrl.data.nofill) !== 'undefined' ? $ctrl.data.nofill : ''; 
					if( typeof( $ctrl.data.newsletter_opt_in) !== 'undefined'  && $ctrl.data.newsletter_opt_in ){
						$ctrl.optin = Optin;
						$ctrl.optin.submit( $ctrl.data )
					}

					// if uploading use the upload service
					if( $ctrl.options.uploading ){
			        Upload.upload({ url: $ctrl.options.action, data : $ctrl.data 
			        }).then(function ( response ) {
				        /// check the success response 
								$ctrl.contact_form_response = response.data;
								if(response.data.contact.success ){
									$ctrl.contact_form_submitting = false
								}else{
									$ctrl.contact_form_submitting = 'error';
									$timeout(function(){
											$ctrl.contact_form_submitting = false
									}, 1500)
								}
			        }, function ( response ) {
				        // there was a server error in uploading
								$ctrl.contact_form_submitting = 'error';
								$timeout(function(){
										$ctrl.contact_form_submitting = false
								}, 1500)
			        }, function (evt) {
				        // track the progress
				        $ctrl.uploadProgress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
			        });
   					 					
					}else{
						// standard form posting / no uploads 
						$http.post($ctrl.options.action, $ctrl.data).then(function(response){
							$ctrl.contact_form_response = response.data;
							if(response.data.contact.success ){
								$ctrl.contact_form_submitting = false
							}else{
								$ctrl.contact_form_submitting = 'error';
								$timeout(function(){
										$ctrl.contact_form_submitting = false
								}, 1500)
							}
						}, function ( response ){ console.log( response ) })	
					}
				}

			},
	    link: function (scope, element, attrs) {
	      if (attrs.ngModel && attrs.mxValue) {
	        $parse(attrs.ngModel).assign(scope, attrs.mxValue);
	      }
	    }
		}
	}])

	.directive('match', ['$parse', function($parse) {
    return {
        require: '?ngModel',
        restrict: 'A',
        link: function(scope, elem, attrs, ctrl) {
            if(!ctrl || !attrs.match) { return; }
            

            var matchGetter = $parse(attrs.match);
            var caselessGetter = $parse(attrs.matchCaseless);
            var noMatchGetter = $parse(attrs.notMatch);
            var matchIgnoreEmptyGetter = $parse(attrs.matchIgnoreEmpty);

            scope.$watch(getMatchValue, function(){
                ctrl.$$parseAndValidate();
            });

            ctrl.$validators.match = function(modelValue, viewValue){
              var matcher = modelValue || viewValue;
              var match = getMatchValue();
              var notMatch = noMatchGetter(scope);
              var value;

              if (matchIgnoreEmptyGetter(scope) && !viewValue) return true;

              if(caselessGetter(scope)) value = angular.lowercase(matcher) === angular.lowercase(match); 
              else value = matcher === match;
              /*jslint bitwise: true */
              value ^= notMatch;
              /*jslint bitwise: false */
              return !!value;
            };

            function getMatchValue(){
                var match = matchGetter(scope);
                if(angular.isObject(match) && match.hasOwnProperty('$viewValue')){
                    match = match.$viewValue;
                }
                return match;
            }
        }
    };
	}])



.directive( 'mxOptin' , [ 'mxOptinService', function( Optin ){
	return {
		
		template : function( ){ },
		link : function( $scope, $element, $attrs ){
			if( typeof( $scope.$ctrl ) !== 'undefined' && typeof( $attrs.mxOptinType !== 'undefined'  ) ) {
				$scope.$ctrl.optin = {
					type : $attrs.mxOptinType
				};
				Optin.init( $attrs);
			}
		},
		controller : function( $scope, $element, $attrs ){
			
		}
	}
}])


.service( 'mxOptinService', [ 'mxOptionMailchimp' , 'mxOptionConstantContact' , 'mxOptionCampaignMonitor', function( MC , CC , CM ){
	var $s = this;
	
	$s.init = function( $options ){
		$_options = {};
		_.each( $options , function( $v , $k ){
			if( $k.indexOf( 'mxOptin') === 0  && $k !== 'mxOptin'){
				$_options[ $k.replace( 'mxOptin' , '' ).toLowerCase()] = $v;
			}
		})
		$options = $_options;
		switch( $options.type ){
			case 'mailchimp' : 
				$s.service = MC; break;
			case 'mc' : 
				$s.service = MC;
			break;
			case 'campaign-monitor' : 
				$s.service = CM;
			break;
			case 'cm' : 
				$s.service = CM;
			break;
			case 'constant-contact' : 
				$s.service = CC;
			break;
			case 'cc' : 
				$s.service = CC;
			break;
			default : 
				$s.service = false;
			break;
		}

		if( $s.service ){
			$s.service.init( $options )
		}
	
	}

	$s.submit = function( $data ){
		if( $s.service ){
			$s.service.submit( $data ).then( function( response ){
				console.log( response )
			}) ;
		}
	}
	$s.response = function(){}
	
	return $s ;
}])

.service( 'mxOptionMailchimp', [ '$http', '$q',  function( $http  , $q ){
	var $s = this;
	$s.method = false;
	$s.data =  { email : false }
	$s.error = false;
	$s.success = false;
	$s.message = false;
	$s.init = function( $options ){
		$s.options = $options;
		$s.method =  typeof( $options.action) !== 'undefined' ? 'simple' : 'api';
	}
	
	$s.merge = function( $data ){
		$s.data = $.extend( $s.data , $data );
	}

	$s.submit = function( $data  ){
		if( typeof( $data ) !== 'undefined' ) $s.merge( $data );
		if( $s.method  && $s.method == 'simple' )		{	
			return $s.submit_simple( );
		}
	}
	
	$s.submit_simple = function(  ){
		var defer = $q.defer();
		$s.error = false;
		$s.success = false;
		$s.message = false;
		$form =$('<form action="" method = "get" ><input type="email" name="EMAIL" value="'+$s.data.email+'" class="form-control required email" id="newsletterEmail" placeholder="Enter email address"/> </form>');
		var $data = $form.serialize();
		
	  $.ajax({
	      type: "GET",
	      url: $s.options.action,
	      data: $data,
	      cache: false,
	      dataType: "jsonp",
	      jsonp: "c", // trigger MailChimp to return a JSONP response
	      contentType: "application/json; charset=utf-8",
	      error: function(error){ },
	      success: function(data){ 
		      $s.result = data;
		      if( data.result == 'success' ){
			      $s.success = true;
			      $s.message = data.msg
		      }else{
			      $s.success = false;
			      $s.message = data.msg
		      }
		      defer.resolve( data )
		    }
		})
		
		return defer.promise;
	}
	
	$s.response = function(){}


	/**
	 * mailchimp_api_modal function.
	 * 
	 * Submits to api in MX_forms 
	 *
	 * @access public
	 * @param mixed $form
	 * @return void
	function mailchimp_api_modal($form){
		$form = typeof($form) !== 'undefined' ? $($form) : $('#form-mailchimp-footer');
		$form.on('submit', function(e){
			e.preventDefault();
			$form.addClass('loading');
			var $data = $form.serialize();
			$('input, button', this).prop('disabled', true);
		  $.ajax({
		      type: "POST",
		      url: $form.attr("action") + '?submit_mailchimp_form=true',
		      data: $data,
		      cache: false,
		      dataType: "json",
		      error: function(error){
						$(this).removeClass('loading');
						$('input, button', this).removeProp('disabled');
		      },
		      success: function(data){
			      mailchimp_success(data, $form)
			     }
			})
			return false;
		})
	}
	 */


	/**
	 * mailchimp_success function.
	 * 
	 * @access public
	 * @param mixed data
	 * @param mixed $form
	 * @return void
	function mailchimp_success(data, $form){
  	if(data.result === 'success'){
    	$('#mc-footer-success .modal-title').text('Almost Done');
    	$('input', $form).val('');
			$form.removeClass('loading');
			$('#mc-footer-success .modal-message').html('<p>' + data.msg + '</p>');
    	$('#mc-footer-success').modal('show');
  	}else if(data.result === 'error'){
    	$('#mc-footer-success .modal-title').text('Subscription Error');
			$form.removeClass('loading');
			$('input, button', $form).removeProp('disabled');
			$('#mc-footer-success .modal-message').html('<p>' + data.msg + '</p>');
    	$('#mc-footer-success').modal('show');
    }
		$form.removeClass('loading').removeProp('disabled');
	}
	 */


	return $s ;
}])


.service( 'mxOptionConstantContact', [ '$http', function( $http  ){
	var $s = this;
	return $s ;
}])


.service( 'mxOptionCampaignMonitor', [ '$http', function( $http  ){
	var $s = this;
	return $s ;
}])





; // end directive s

})()


;// end function scoep