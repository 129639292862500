( function(){
	// ############## 
	//	Directives // These can be removed + reused
	// ##############
	var directives = angular.module('mx.directives');
	directives.requires.push( 'ngFileUpload' );

	// -------------------------------------------------------
	//  Submits to the abstract contact form class 
	//  @DEPRECATED Use mxFormComponent instead
	// -------------------------------------------------------
	directives.directive('mxForm', ['$parse', '$http', 'Upload', function($parse, $http, Upload){
		return {
			controller : function($scope, $http, $timeout, $attrs ){
				$scope.jsfilled = 'filled';
				try {
					$scope.mxFormOptions = JSON.parse( $attrs.mxFormOptions);
				}catch( $e ){ console.log( $e ); }
				
				console.log( $scope.mxFormOptions)
				/**
				 * Submit to our abstract function to handle all form submissions
				 * 
				 * @var mixed
				 * @access public
				 */
				$scope.submit_contact_form_abstract = function(form , action, $uploading){
					action = action !== 'NO' ?  action  : '/?submit_contact_form_abstract';
					$uploading = $uploading == "YES" ? true : false;
					$scope.contact_form_submitting = true;
					form.nofill = typeof(form.nofill) !== 'undefined' ? form.nofill : ''; 

					// if uploading use the upload service
					if( $uploading ){
			        Upload.upload({ url: action, data : form 
			        }).then(function ( response ) {
				        /// check the success response 
								$scope.contact_form_response = response.data;
								if(response.data.contact.success ){
									$scope.contact_form_submitting = false
								}else{
									$scope.contact_form_submitting = 'error';
									$timeout(function(){
											$scope.contact_form_submitting = false
									}, 1500)
								}
			        }, function ( response ) {
				        // there was a server error in uploading
								$scope.contact_form_submitting = 'error';
								$timeout(function(){
										$scope.contact_form_submitting = false
								}, 1500)
			        }, function (evt) {
				        // track the progress
				        $scope.uploadProgress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
				        console.log( )
			        });
   					 					
					}else{
						// standard form posting / no uploads 
						$http.post(action, form).then(function(response){
							$scope.contact_form_response = response.data;
							if(response.data.contact.success ){
								$scope.contact_form_submitting = false
							}else{
								$scope.contact_form_submitting = 'error';
								$timeout(function(){
										$scope.contact_form_submitting = false
								}, 1500)
							}
						})
					}
				}
			},
	    link: function (scope, element, attrs) {
	      if (attrs.ngModel && attrs.mxValue) {
	        $parse(attrs.ngModel).assign(scope, attrs.mxValue);
	      }
	    }
		}
	}])

})()


;// end of directive