	// ############## 
	//	Directives // These can be removed + reused
	// ##############
	angular.module('mx.directives')

	
	// -------------------------------------------------------
	// -------------------------------------------------------
	.directive( 'mxVideoPlayer', ['$http', function($http){
		return {
			link : function($scope, $element, $attrs){
					
				var video = $($element.find('video'));
				var play_btn = $($element.find('[btn-play]'));
				play_btn.css('pointer-events', 'none');
				
				var  play = function(){
					play_btn.fadeOut( 'fast', function(){
						$element.addClass('loaded');
						video[0].play();	
						video.attr('controls', '');
					})
				}
				var pause = function(){
					$element.removeClass('loaded');
					video[0].pause();	
					video.removeAttr('controls')
					play_btn.fadeIn('fast');
				}
				video.on('click', function(){
					if(video[0].paused)  play();
					else pause();
				})

			}
		}
	}])

	// -------------------------------------------------------
	// Video Player Wrapper V2
	// -------------------------------------------------------
	.directive( 'mxVideoPlayerComponent', ['$http' , '$timeout', function($http, $timeout ){
		return {
			scope : { },
			template : function ( scope, element ){ }, // template 
			link : function($scope, $element, $attrs){
				$scope.video.on('click', function(){
					if( $scope.pauseOnClick){
						if($scope.video.paused)  $scope.$ctrl.play();
						else $scope.$ctrl.pause();
					}
				})

				$scope.mute_btn.on('click', function(){
					$scope.$ctrl.mute();
				})
				
				$scope.play_btn.on('click', function(){
					$scope.$ctrl.play();
				})

				$scope.video_.oncanplay = function() {
					$element.addClass('loaded')
					$scope.video_.play();
					$scope.started = true;
				};

			},
			controller : function($scope, $element, $attrs, $timeout ){
				var $ctrl = $scope.$ctrl = this;

				var init = function(){
					$scope.video 				= $($element.find('video'));
					$scope.video_ 			=	 $($element.find('video'))[0];
					$scope.play_btn 		= $($element.find('[btn-play]'));
					$scope.mute_btn 		= $($element.find('[btn-mute]'));
					$scope.muted 				= $scope.video.prop('muted' );
					$scope.playing 			= $scope.video.prop('autoplay' );
					$scope.pauseOnClick = $attrs.pauseOnClick != 'undefined' ? $attrs.pauseOnClick : false;
				}

				$ctrl.pause = function(){
					$element.removeClass('loaded');
					$scope.video_.pause();	
// 					$scope.video.removeAttr('controls')
// 					$scope.play_btn.fadeIn('fast');
				}// pause function 
				
				$ctrl.mute = function(){
					if( $scope.muted ){
						$scope.video.prop( 'muted' , false );
						$scope.muted = false
					} else{
						$scope.video.prop( 'muted' , true )
						$scope.muted = true
					}
					$scope.$apply();
				}// mute function


				$ctrl.play = function(){
					if( $scope.playing ){
						$scope.video_.pause();
						$scope.playing = false
					} else{
						$scope.video_.play();
						$scope.playing = true
					}
					$scope.$apply();
				}
				init();
			}
		}// controller 
	}])// mx-video-player-component


	// -------------------------------------------------------
	// -------------------------------------------------------
	.directive( 'mxVimeoPlayer', ['$http', function($http){
		return {
			link : function($scope, $element, $attrs){
				if( ! Vimeo  ){
					$.getScript( 'https://player.vimeo.com/api/player.js', function () {
						init();
					})
				}else{ init(); }
				function init(){
					var play_btn = $($element.find('[btn-play]'));
					var $external = $attrs.external || true;

					play_btn.on('click', function(){
						$($element).addClass( 'playing' );
						player.play().then(function() { }) 
					})
	
			    var iframe = $element.find('iframe');
			    var player = new Vimeo.Player(iframe);
			    player.on('pause', function(){
				    $($element).removeClass( 'playing');
			    })
				}

			}
		}
	}])
	
	;// end of directive


