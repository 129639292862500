	// ############## 
	//	Directives // These can be removed + reused
	// ##############
	angular.module('mx.directives')
	// -------------------------------------------------------
	// MX Star Picker 
	/** 
		* Star Picker to use in forms 
		* $attrs {
		* 	star	: refernce to the outside data model ( e.g data.star_picker )  
		* 	staCount : int the number of stars to show 
		* 	full : class to use when the star is full
		* 	empty : class to use when the star is empty :: defaults to fa fa-star text-default
		* } 
		* 
		* Usage: 
		*	<div class = 'stars text-warning' ng-model= "data.star_rating" mx-star-picker="data.star_rating">
		*		<a class = '{{star.className}}' ng-repeat="star in $ctrl.stars" mx-star-picker-star="star" ></a>
		*	</div>
		* 
	**/
	// -------------------------------------------------------
	.directive( 'mxStarPicker', ['$http', function($http){
		return {
			scope : { star : '=mxStarPicker', ngModel : '=ngModel', },
			require : 'ngModel',
			link : function( $scope, $element, $attrs , ngModel ){
				$scope.updateModel = function( $star ){
					ngModel.$setViewValue( $star )
				}
			}, // link
			template : function ( scope, element ){ }, // template 
			controller : function($scope, $element, $attrs, $timeout ){
				var $ctrl = $scope.$ctrl = this;
				$timeout( function(){ console.log( $scope )});
				$ctrl.starCount = typeof( $attrs.starCount )!== 'undefined' ? $attrs.starCount : 5;
				$ctrl.full = typeof( $attrs.full )!== 'undefined' ? $attrs.full : 'fa fa-star';
				$ctrl.empty = typeof( $attrs.empty )!== 'undefined' ? $attrs.empty : 'fa fa-star fa-star-empty empty text-default';
				$ctrl.stars = [];

				var i = 1;while(  i <= $ctrl.starCount){
					$ctrl.stars.push( { position : i, className : $ctrl.empty } );
					i++;
				}
				$ctrl.starClicked = function( $star ){
					$ctrl.ratingChanged( $star.position );
					$ctrl.star = $star;
					$scope.updateModel( $star.position );
					$timeout( function ( ) { $scope.$apply(); } );
				} // starClicked
				
				$ctrl.ratingChanged = function( $position ){
					$ctrl.starRating = $position;
					_.each( $ctrl.stars , function ( $star ){
						if( $star.position <= $position ){
							$star.className = $ctrl.full
						}else{
							$star.className = $ctrl.empty
						}
						$timeout( function () { } )
					})
				} // ratingChanged
			}// controller 
		}// return 
	}]) // mxStarPicker 

	// -------------------------------------------------------
	// MX Star Picker Star
	/** 
		* Star direcitve for Star Picker
		* $attrs { 
		* 	star : reference to the model in the parent
		* } 
		* 
		* Usage: 
		*	<div class = 'stars text-warning' ng-model= "data.star_rating" mx-star-picker="data.star_rating">
		*		<a class = '{{star.className}}' ng-repeat="star in $ctrl.stars" mx-star-picker-star="star" ></a>
		*	</div>
		* 
	**/
	// -------------------------------------------------------
	.directive( 'mxStarPickerStar', ['$http', function($http){
		return {
			scope : {
				star : '=mxStarPickerStar'
			},
			require : '^mxStarPicker',
			link : function( scope, element, attrs , mxStarPicker){
				$(element).attr('style', 'cursor:pointer;');
				$(element).attr( 'class' , scope.star.className );
				$(element).on( 'click', function(){
					mxStarPicker.starClicked( scope.star )
				})
				$(element).on( 'mouseover', function(){
				})
			}, // link 
			//template : function ( scope, element ){ },
			controller : function($scope, $element, $attrs){
				var $ctrl = $scope.$ctrl = this;
			}// controller 
		}// return 
	}]) // mxStarPickerStar 
	
	
	;// end of directive