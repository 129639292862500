	
	// ############## 
	//	Directives // These can be removed + reused
	// ##############
	angular.module('mx.directives')

	// -------------------------------------------------------
	// -------------------------------------------------------
	.directive( 'mxAgeGate', ['$http', '$window', function( $http, $window ){
		return {
			template : function( $a , $b ){ return $a.html(); },
			link : function($scope, $element, $attrs){
			},
			controller : function( $scope , $element, $window, $attrs , $timeout ){
				var $check_age = true;
				var today = new Date()
				var priorDate = new Date().setDate(today.getDate()-30)
				var $ctrl = this;
				var months = []
				if(typeof( $attrs.checkAge ) !== 'undefined'){
					if( $attrs.checkAge == 'false' ) $check_age = false;
				}
				$('body').addClass('age-gated');
				
				$ctrl.ready = true;
				$ctrl.message = "How Old Are You?"
				$ctrl.acceptances = ["Let's Party!"];
				$ctrl.rejections = ["Not Today Sonny!"];
				$ctrl.old_enough = false;
				$('#age-gate-select').removeClass( 'disabled');
				if($check_age  && typeof( $screen_data ) !== 'undefined' ){
					$ctrl.options = $screen_data.age_gate;
					$ctrl.date = new Date();
					$ctrl.ag = {
						month : this.date.getMonth(),
						day : this.date.getDate(),
						year : parseInt(this.options.current.year)
					}
					_.each( $ctrl.options.months, function( label , value ){
						months.push({ 'value' : value , 'label' : label })
					});
	
					$ctrl.options.months = months;
					
	
					$ctrl.check_age = function($first){
						$first = $first || false;
						var user_age = new Date( $ctrl.ag.year , $ctrl.ag.month , $ctrl.ag.day );
						var years = moment().diff(user_age, 'years');
						$ctrl.old_enough = years  >= 21 ;
						if($first || $ctrl.old_enough)
							this.message =  $ctrl.acceptances[ Math.floor( Math.random() * $ctrl.acceptances.length ) ];
						else
							this.message =  $ctrl.rejections[ Math.floor( Math.random() * $ctrl.rejections.length ) ];
					}
					
					$ctrl.check_age(true);
				}
				
				$ctrl.submit = function(){
					Cookies.set('passed_age_gate', 'true', { expires: 7 });
					//$http.get( '/?pass_age_gate')
					$('body').removeClass( 'age-gated' );
					$element.fadeOut('fast',  function(){$(this).remove()});
				}
				if(Cookies.set('passed_age_gate') == "true" ){
					$ctrl.submit();
					return false;
				}else{
					$timeout( function(){$element.addClass('in')});
					//$element.addClass('in');
/*
					$element.animate(
						{ opacity : 1},
						3000
					)
*/
				}
			},
			controllerAs : 'AgeGateCtrl',
		}
	}])
	
	
	;// end of directive
	
