	// ############## 
	//	Directives // These can be removed + reused
	// ##############
	angular.module('mx.directives')

	
	// -------------------------------------------------------
	// -------------------------------------------------------
	.directive( 'mxConstantContact', ['$http', function($http){
		return {
			scope : {},
			link : function( scope, element, attrs ){},
			template : function ( scope, element ){ },
			controller : function($scope, $element, $attrs){
				var $ctrl = $scope.$ctrl = this;
				$ctrl.data = {
					ca : $attrs.ca || false,
					list : $attrs.list || false
				}
				$ctrl.submitting = false;
				$ctrl.success = false;
				$ctrl.error = false;
				$url = 'https://visitor2.constantcontact.com/api/signup';
				$ctrl.submit =function (){
					$ctrl.submitting = true;
					$ctrl.success = false;
					$ctrl.error = false;
					if( !$ctrl.data.ca || !$ctrl.data.list || !$ctrl.data.email ) return false;
					$http.post( $url , $ctrl.data  ).then(
						function( response ){
							$ctrl.submitting = false;
							$ctrl.success = response.data.success;
							if( !$ctrl.success) $ctrl.error = 'There was an error signing up';
						},
						function(response ){
							console.log( response )
							$ctrl.submitting = false;
							$ctrl.success = false;
							$ctrl.error = 'There was an error signing up';
					});// then 
				}// submit 
			}// controller 
		}// return 
	}]) // mxConstantContact 
	
	
	;// end of directive
